
import { computed, defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import router from "@/router";
import moment from "moment";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {},
  data() {
    return {
      current_page: 0,
      loading: false,
      filter: {
        dificulty: "",
        status: "",
        search: ""
      }
    };
  },
  setup() {
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));



    onMounted(() => {
      setCurrentPageTitle("Machines List");
    });

    store.dispatch(Actions.GET_PATHWAYS);

    const pathways = computed(() => {
      return store.getters.getAllPathways;
    });


    return {
      moment,
      router,
      pathways,
      store,
      currentLanguage
    };
  },
  methods: {  }
});
